@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Victor+Mono&display=swap');

.orbitronFont {
  font-family: 'Orbitron', sans-serif;
}

.victormono {
  font-family: 'Victor Mono', monospace;
}

.navigationFont {
  font-size: 20px;
  font-family: 'Orbitron', sans-serif;
  font-weight: bold;

  @media screen and (max-width: 576px){
    font-size: 15px;
  }
}

.navback {
  background-size: 0%;
  background-repeat: no-repeat;
  background-position: right;

  @media screen and (max-width: 576px){
    background-size: 0%;
    background-repeat: no-repeat;
    background-position: right;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    border-bottom: solid #FFF2CC
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    
  }
}

.projects {
  font-family: 'Orbitron', sans-serif;
  font-weight: 'bold'; 
  font-size: 80px;
  color: #FFF2CC;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (max-width: 576px){
    font-size: 52px;
    margin-top: 40px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    margin: 20px 0px;
    font-size: 70px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    margin: 10px 0px;
    font-size: 60px;
  }
}

.aboutme {
  font-family: 'Orbitron', sans-serif;
  font-weight: 'bold'; 
  color: #FFF2CC;
  margin-top: '350px';
}

.contactme {
  color: #000000;
  font-family: 'Orbitron', sans-serif;

  @media screen and (max-width: 768px){
    margin-bottom: -100px;
  }
}

.h1_heading {
  font-size: 100px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 576px){
    font-size: 64px;
    margin-top: 30px;
  }
}

.h3_heading {
  font-size: 30px;
  font-weight: bold;

  @media screen and (max-width: 576px){
    font-size: 20px;
    margin-top: -10px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 22px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 29px;
    margin-top: -10px;
  }
}

hr {
  border: double 3px;
  animation-name: bar;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-delay: 5s;
  animation-duration: 3s;  
  
  @media screen and (max-width: 576px) {
    animation-name: barSm;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    animation-name: barMed;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    animation-name: barLg
  }
}

@keyframes bar {
  0%   { width: 0%; color: #FFF2CC;}
  25%  {background-color:greenyellow;}
  50%  {background-color:green;}
  75%  {background-color:greenyellow;}
  100% { width: 87%; background-color:#FFF2CC; }
}

@keyframes barSm {
  0%   { width: 0%; color: #FFF2CC;}
  25%  {background-color:greenyellow;}
  50%  {background-color:green;}
  75%  {background-color:greenyellow;}
  100% { width: 55%; background-color:#FFF2CC; }
}

@keyframes barMed {
  0%   { width: 0%; color: #FFF2CC;}
  25%  {background-color:greenyellow;}
  50%  {background-color:green;}
  75%  {background-color:greenyellow;}
  100% { width: 82%; background-color:#FFF2CC; }
}

@keyframes barLg {
  0%   { width: 0%; color: #FFF2CC;}
  25%  {background-color:greenyellow;}
  50%  {background-color:green;}
  75%  {background-color:greenyellow;}
  100% { width: 74%; background-color:#FFF2CC; }
}

.aboutme_paragraph {
  font-size: 18.5px;
  font-family: 'Victor Mono', monospace;
  text-align: justify;
  margin: 0.8rem 2rem;

  @media screen and (max-width: 576px) {
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 15px;
    margin: 10px 90px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 16px;
    margin-left: 15px;
    margin-right: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.projects_paragraph {
  font-size: 18px;
  margin: 20px 130px;
  font-family: 'Victor Mono', monospace;
  text-align: justify;

  @media screen and (max-width: 576px) {
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 15px;
    margin: 10px 90px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 16px;
    margin-left: 15px;
    margin-right: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.subTitle {
  font-size: 20px;

  @media screen and (max-width: 576px) {
    font-size: 14px;
    margin-bottom: 0px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 18px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 18px;
  }

}

.circuit1 {
  background-image: url('./app/assets/Slide1_Graphics.png');
  background-size: 4.93%;
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 576px){
    background-size: 0%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    background-size: 11.52%; 
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    background-size: 5.91%;
  }
}

.circuit2 {
  background-image: url('./app/assets/Slide2_Graphics.png');
  background-size: 9%;
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 576px){
    background-image: url('./app/assets/Slide1_Graphics.png');
    background-size: 0%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    background-size: 20.9%; 
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    background-size: 10.7%;
  }
}

.circuit3 {
  background-image: url('./app/assets/Slide3_Graphics.png');
  background-attachment: fixed;
  background-size: 16.9%;
  background-position: right; 
  background-repeat: no-repeat;

  @media screen and (max-width: 576px){
    background-size: 0%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    background-size: 39.6%; 
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    background-size: 20.3%;
  }
}

/* The below css code was copied from react-spring viewpager example */
.appTitleTop {
  font-size: 40px;

  @media screen and (max-width: 576px){
    font-size: 20px;
    margin-top: 10px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 20px;
    margin-top: -8px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 30px;
    margin-top: -12px;
  }
}

.appTitleBottom {
  font-size: 30px;

  @media screen and (max-width: 576px){
    font-size: 20px;
    margin-top: 10px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 17px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 24px;
  }
}

.page {
  position: absolute;
  width: 40%;
  height: 70%;
  will-change: transform;

  @media screen and (max-width: 576px){
    width: 100%;
    height: 45%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    width: 70%;
    height: 45%;
    margin: 40px 125px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    width: 45%;
    height: 70%;
  }
}

.page > div {
  touch-action: none;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  will-change: transform;
  box-shadow: 0 62.5px 125px -25px rgba(255, 242, 204, 0.5), 0 37.5px 75px -37.5px rgba(255, 242, 204, 0.6);
  border: 15px solid black;
  border-radius: 110px;
}

.wrapper {
  width: 100%;
  height: 100%;
}

/* CSS classes for BoxSpread from React Spring */
.boxSpreadWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.boxSpreadContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(20%, 1fr));
  grid-gap: 20px;
  padding: 70px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
  will-change: width, height;

  @media screen and (max-width: 576px){
    grid-gap: 15px;
    padding: 40px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    grid-gap: 15px;
    padding: 40px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    grid-gap: 15px;
    padding: 40px;
  }
}

.boxSpreadItem {
  padding: 10px 0px;
  width: 75%;
  height: 75%;
  border-radius: 10px;
  will-change: transform, opacity;

  @media screen and (max-width: 576px){
    width: 100%;
    height: 70%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    width: 100%;
    height: 70%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    width: 100%;
    height: 70%;
  }
}

.skillIcon{
  margin: 1px 0px;
  font-size: 13px;

  @media screen and (max-width: 576px){
    width: 100%;
    height: 75%;
    font-size: 10px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    width: 100%;
    height: 75%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    width: 100%;
    height: 75%;
    font-size: 10px;
  }
}

/* CSS Styles for the Accordion in the About Me section. */
.accordion {
  width: 85%;
  margin-top: 20px;

  @media screen and (max-width: 576px){
    width: 100%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    width: 90%;
    margin: 30px 42px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    width: 85%;
  }
}

.accordionItem {
  background-color: #FFFBEF;
}

.accordionButton {
  font-family: 'Orbitron', sans-serif;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: left;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: #57554F;
  text-align: left;
  background-color: #969489;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);

  @media screen and (max-width: 576px){
    width: 100%;
    font-size: 17px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 19px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 26px;
  }
}

/* .accordionButton::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.accordionButton:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  transform: var(--bs-accordion-btn-icon-transform);
} */

/* .accordionButton:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
} */

.liFlex {
  display: flex;
  justify-content: flex-start;
}

.liIcon {
  @media screen and (max-width: 576px){
    width: 15%;
    margin-right: 5px;  
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    width: 10%;
    margin-right: 10px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    width: 15%;
    margin-right: 6px;
  }

  @media screen and (min-width: 1200.02px){
    width: 15%;
    margin-right: 20px;
  }
}

.liHeader {
  font-family: 'Orbitron';
  font-weight: bold;
  color: #57554F;

  @media screen and (max-width: 576px){
    font-size: 14px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    font-size: 24px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 13px;
  }

  @media screen and (min-width: 1200.02px){
    font-size: 27px;
  }
}

.liSubHeader1 {
  font-family: 'Orbitron', sans-serif;
  margin-top: -70px;

  @media screen and (max-width: 576px){
    margin-left: 63px;
    font-size: 10px;
    margin-top: -37px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    margin-left: 77px;
    font-size: 12px;
    margin-top: -30px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    margin-left: 67px;
    font-size: 11px;
    margin-top: -40px;
  }

  @media screen and (min-width: 1200.02px){
    margin-left: 133px;
    margin-top: -70px;
    font-size: 15px;
  }
}

.liSubHeader2 {
  font-family: 'Orbitron', sans-serif;

  @media screen and (max-width: 576px){
    margin-left: 63px;
    font-size: 10px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    margin-left: 77px;
    font-size: 12px;
    margin-top: 5px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    margin-left: 67px;
    font-size: 11px;
  }

  @media screen and (min-width: 1200.02px){
    margin-left: 133px;
    margin-top: 7px;
    font-size: 15px;
  }
}

.liSubHeader3 {
  font-family: 'Victor Mono', sans-serif;
  margin-top: 40px;

  @media screen and (max-width: 576px){
    font-size: 12px;
    margin-left: 3px;
    margin-bottom: 10px;
    margin-top: 17px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 17px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 12px;
    margin-left: -0.1px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1200.02px){
    margin-top: 40px;
    margin-bottom: 15px;
    margin-left: 40px;
    font-size: 20px ;
  }
}

.liBullet {
  @media screen and (max-width: 576px) {
    font-size: 9px;
    margin-left: -15px;
    word-break: break-all;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 15px;
    margin-left: 0px;
    word-break: break-all;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 9px;
    margin-left: -15px;
    word-break: break-all;
  }

  @media screen and (min-width: 1200.02px){
    font-size: 16px;
    margin-left: 25px;
  }
}

.carouselLeft {
  left: -50px;
  opacity: 1%;
  
  @media screen and (max-width: 576px){
    left: -40px;
    opacity: 1%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    left: -56px;
    opacity: 1%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    left: -40px;
    opacity: 1%;
  }
}

.carouselRight {
  right: -50px;
  opacity: 1%;

  @media screen and (max-width: 576px){
    right: -40px;
    opacity: 1%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    right: -50px;
    opacity: 1%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    right: -40px;
    opacity: 1%;
  }
}

.finger {
  display: flex;
  justify-content: center;
  height: 400px;
  margin-left: -135px;

  @media screen and (max-width: 576px){
    height: 200px;
    margin-left: -335px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    /* margin-top: -240px; */
    height: 200px;
    margin-left: -84px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    height: 200px;
    margin-left: -26px;
  }
}

.contactform {
  margin-left: 130px;
  margin-top: 70px;

  @media screen and (max-width: 576px){
    margin-left: 0px;
    margin-top: 30px;
  }

  @media screen and (min-width: 576.02px) and (max-width: 991px){
    margin-top: 0px;
    margin-left: 35px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    margin-top: -60px;
    margin-left: 200px;
  }
}
