html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/* The below css is for the Chaining Transition and Spring
   project section.
*/
.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}